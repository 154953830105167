<template>
	<div class="ws-pill is-size-7" :class="`is-${props.type}`">
		<slot></slot>
	</div>
</template>

<script setup>
const props = defineProps({
	type: {
		type: String,
		default: "default",
		validator: (value) =>
			["default", "success", "warning", "danger", "info"].includes(value)
	}
});
</script>
<style lang="scss" scoped>
.ws-pill {
	border-radius: 25px;
	display: inline-flex;
	align-items: center;
	gap: 4px;
	text-wrap: nowrap;

	background-color: $color-grey-100;
	border: 1px solid $color-grey-500;
	padding: 3px 7px;
	line-height: 16px;

	&.is-success {
		background-color: $color-success-50;
		border: 1px solid $color-success-500;
	}
	&.is-warning {
		background-color: $color-warning-50;
		border: 1px solid $color-warning-500;
	}
	&.is-danger {
		background-color: $color-danger-50;
		border: 1px solid $color-danger-500;
	}
	&.is-info {
		background-color: $color-info-50;
		border: 1px solid $color-info-500;
	}
}
</style>
